var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCol',{attrs:{"md":"12"}},[_c('CCard',{attrs:{"accent-color":"warning"}},[_c('CCardHeader',[_c('CRow',[_c('CCol',{attrs:{"md":"10"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Báo cáo Khách Hàng, Đơn Hàng - ")]),_c('span',{staticClass:"text-info font-weight-bold"},[_vm._v(" "+_vm._s(_vm.timeBox.label)+" ")])]),_c('CCol',{attrs:{"md":"2"}},[_c('div',{staticClass:"card-header-actions "},[_c('CLink',{staticClass:"card-header-action btn-minimize ml-3",on:{"click":function($event){_vm.isCollapsed = !_vm.isCollapsed}}},[_c('CIcon',{attrs:{"name":("cil-chevron-" + (_vm.isCollapsed ? 'bottom' : 'top'))}})],1)],1),_c('div',{staticClass:"card-header-actions"},[_c('CDropdown',{attrs:{"color":"transparent p-0","caret":false,"placement":"bottom-end","size":"sm"},scopedSlots:_vm._u([{key:"toggler-content",fn:function(){return [_c('CIcon',{attrs:{"name":"cil-calendar","custom-classes":"c-icon text-dark"}})]},proxy:true}])},_vm._l((_vm.timeList),function(time,j){return _c('CDropdownItem',{key:("time-" + j),class:{
                                active:
                                    _vm.timeBox &&
                                    time.fromDateCurrent == _vm.timeBox.fromDateCurrent &&
                                    time.toDateCurrent == _vm.timeBox.toDateCurrent,
                            },on:{"click":function($event){_vm.timeBox = time}}},[_vm._v(" "+_vm._s(time.label)+" ")])}),1)],1)])],1)],1),_c('CCollapse',{attrs:{"show":!_vm.isCollapsed,"duration":500}},[_c('CCardBody',[(_vm.loading)?_c('div',[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang tải... ")],1):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-end mb-3"},[_c('v-select',{staticStyle:{"width":"300px"},attrs:{"multiple":"","options":[
                        { Id: 'All', Name: 'Tất cả' } ].concat( _vm.agencies.map(function (_) {
                            return {
                                Id: _.Id.toString(),
                                Name: _.Name,
                            };
                        }) ),"reduce":function (p) { return p.Id; },"label":"Name"},model:{value:(_vm.AgencyIds),callback:function ($$v) {_vm.AgencyIds=$$v},expression:"AgencyIds"}})],1),_c('CRow',_vm._l((_vm.customerOrderList),function(customerOrder){return _c('CCol',{staticClass:"customer-order-card",attrs:{"md":"4"}},[_c('CWidgetDropdown',{attrs:{"color":customerOrder.countCurrent > customerOrder.countPast
                            ? 'success'
                            : customerOrder.countCurrent === customerOrder.countPast
                                ? 'info'
                                : 'danger',"text":("Số lượng : " + (customerOrder.countCurrent > 0
    ? customerOrder.countCurrent.toString()
    : '0')),"header":customerOrder.title},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"m-4 font-italic font-weight-bold"},[_c('CIcon',{attrs:{"name":customerOrder.countCurrent > customerOrder.countPast
                                        ? 'cilChevronTop'
                                        : customerOrder.countCurrent ===
                                            customerOrder.countPast
                                            ? 'cilMinus'
                                            : 'cilChevronBottom'}}),_vm._v(" "+_vm._s(_vm.percentage( customerOrder.countPast, customerOrder.countCurrent ) + " %")+" "),(customerOrder.qtyOrderCurrent !== null)?_c('span',{staticClass:"float-right"},[_vm._v("SL Đơn hàng : "+_vm._s(customerOrder.qtyOrderCurrent + " (" + (_vm.percentage(customerOrder.qtyOrderPast, customerOrder.qtyOrderCurrent)) + " %)"))]):_vm._e(),(customerOrder.amountAverage !== null)?_c('span',{staticClass:"float-right"},[_vm._v("TB đơn ghế : "+_vm._s(customerOrder.amountAverage.toLocaleString("en-US") + " vnd"))]):_vm._e()],1)]},proxy:true}],null,true)})],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }