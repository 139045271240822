<template>
    <CChartBar :datasets="defaultDatasets" :labels="labels" :options="{
        scales: {
            xAxes: [{
                stacked: true
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    userCallback: (value, index, values) => {
                        return new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND',
                            maximumSignificantDigits: 3
                        }).format(value)
                    }
                }
            }],
        },
        tooltips: {
            mode: 'index',
            callbacks: {
                label: (tooltipItem, data) => {
                    return new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND',
                        maximumSignificantDigits: 3
                    }).format(tooltipItem.yLabel)
                }
            }
        },
        maintainAspectRatio: false,
    }" />
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs';;
export default {
    props: ["sourceChart"],
    components: {
        CChartBar
    },
    computed: {
        labels() {
            let sourceCharts = this.sourceChart;
            return [...sourceCharts].map((_) => `Tháng ${_.monthNo}`)
        },

        defaultDatasets() {
            let sourceCharts = this.sourceChart;
            let months = [...sourceCharts].map((_) => _.monthNo)
            return [
                {
                    label: 'Doanh thu',
                    borderWidth: 2,
                    fill: false,
                    backgroundColor: '#e41e3f',
                    borderColor: '#e41e3f',
                    type: 'line',
                    wrapper: false,
                    order: 0,
                    data: [...months].map((month) => {
                        let item = sourceCharts.find((source) => source.monthNo === month);
                        return item ? item.incomeTotal : 0
                    }),
                },
                {
                    label: 'Chi Phí',
                    data: [...months].map((month) => {
                        let item = sourceCharts.find((source) => source.monthNo === month);
                        return item ? item.feeTotal : 0
                    }),
                    backgroundColor: 'rgba(252,229,136, 0.5)',
                    borderColor: 'rgba(252,229,136, 0.5)',
                    borderWidth: 3,
                    barPercentage: 1.2,
                    order: 1
                },
                {
                    label: 'Doanh Số',
                    data: [...months].map((month) => {
                        let item = sourceCharts.find((source) => source.monthNo === month);
                        return item ? item.saleTotal : 0
                    }),
                    backgroundColor: '#0a558c',
                    borderColor: '#0a558c',
                    borderWidth: 1,
                    categoryPercentage: 0.5,
                    order: 2
                }
            ];
        }
    }
}
</script>