var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CChartBar',{attrs:{"datasets":_vm.defaultDatasets,"labels":_vm.labels,"options":{
    scales: {
        xAxes: [{
            stacked: true
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true,
                userCallback: function (value, index, values) {
                    return new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND',
                        maximumSignificantDigits: 3
                    }).format(value)
                }
            }
        }],
    },
    tooltips: {
        mode: 'index',
        callbacks: {
            label: function (tooltipItem, data) {
                return new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                    maximumSignificantDigits: 3
                }).format(tooltipItem.yLabel)
            }
        }
    },
    maintainAspectRatio: false,
}}})}
var staticRenderFns = []

export { render, staticRenderFns }