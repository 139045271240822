<template>
    <CRow>
        <CCol md="12">
            <CCard accent-color="info">
                <CCardHeader>
                    <CRow>
                        <CCol md="10">
                            <span class="font-weight-bold">
                                Doanh số, Doanh thu, Chi phí -
                            </span>
                            <template v-if="fromDate && toDate">
                                ({{
                                    $moment(fromDate)
                                        .local()
                                        .format("DD/MM/YYYY")
                                }}
                                -
                                {{
                                    $moment(toDate)
                                        .local()
                                        .format("DD/MM/YYYY")
                                }})
                            </template>
                        </CCol>
                        <CCol md="2">
                            <div class="card-header-actions">
                                <CLink class="card-header-action btn-minimize" @click="isCollapsed = !isCollapsed">
                                    <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" />
                                </CLink>
                            </div>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCollapse :show="!isCollapsed" :duration="500">
                    <CCardBody>
                        <div v-if="loading">
                            <CSpinner color="info" size="sm" class="ml-2" />
                            Đang tải...
                        </div>
                        <div class="d-flex align-items-center justify-content-end mb-5">
                            <div class="d-flex align-items-center">
                                <span class="font-weight-bold mr-1 text-primary">Từ ngày</span>
                                <datetime type="date" format="dd/MM/yyyy" v-model="fromDate" input-class="form-control"
                                    value-zone="local">
                                </datetime>
                            </div>
                            <div class="d-flex align-items-center mr-5 ml-5">
                                <span class="font-weight-bold mr-1 text-primary">Đến ngày</span>
                                <datetime type="date" format="dd/MM/yyyy" v-model="toDate" input-class="form-control"
                                    value-zone="local">
                                </datetime>
                            </div>
                            <v-select class="mr-5" style="width: 700px;" v-model="AgencyIds" multiple :options="[
                                { Id: 'All', Name: 'Tất cả' },
                                ...agencies.map((_) => {
                                    return {
                                        Id: _.Id.toString(),
                                        Name: _.Name,
                                    };
                                }),
                            ]" :reduce="(p) => p.Id" label="Name" />
                            <CButton color="info" @click="loadIncome">Xem báo cáo</CButton>
                        </div>
                        <Chart style="height: 600px;" :sourceChart="this.sourceChart" />
                    </CCardBody>
                </CCollapse>
            </CCard>
        </CCol>
        <CustomerOrder :agencies="this.agencies" />
    </CRow>
</template>

<script>
import Chart from "./Chart.vue";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import CustomerOrder from "./CustomerOrder.vue";

export default {
    name: "chart-test",
    components: {
        Chart,
        Datetime,
        CustomerOrder
    },
    data() {
        return {
            agencies: [],
            loading: false,
            showingRange: false,
            AgencyIds: "All",
            sourceChart: [],
            fromDate: this.$moment()
                .startOf("year")
                .toISOString(),
            toDate: this.$moment(new Date())
                .endOf("day")
                .toISOString(),
            isCollapsed: true,
        };
    },
    created() {
        this.loadAgencyInfo();
    },
    methods: {
        async loadAgencyInfo() {
            try {
                this.loading = true;
                let resp = await this.$http.get(`odata/Agency`, {
                    params: {
                        $select: "Id,Name",
                    },
                });
                if (resp.data.value) {
                    this.agencies = resp.data.value;
                }
                this.loading = false;
            } catch (error) {
                alert(error);
            }
        },

        async loadIncome() {
            // let listIds = [];
            // if (this.AgencyIds === "All") {
            //     listIds = [...this.agencies.map((_) => _.Id.toString())].join(",");
            // }
            var params = {
                fromDate: this.fromDate,
                toDate: this.toDate,
                AgencyIds: this.AgencyIds === "All"
                        ? "All"
                        : [...this.AgencyIds.map((_) => _.toString())].join(","),
            };
            try {
                this.loading = true;
                let resp = await this.$http.get(`Report2/AgencyIncome`, {
                    params: params,
                });
                if (resp.data) {
                    this.sourceChart = resp.data;
                }
                this.loading = false;
            } catch (error) {
                alert(error);
            }
        },
    },
    watch: {
        isCollapsed(val) {
            if (val === false) {
                this.AgencyIds = "All"
                this.loadIncome();
            }
            else {
                this.sourceChart = [];
                this.fromDate = this.$moment().startOf("year").toISOString()
            }
        },
        AgencyIds(val) {
            if (val.length > 1 && val[val.length - 1] === "All") {
                this.AgencyIds = "All"
            }

            if (val[0] === "All" && val.length > 1) {
                this.AgencyIds = [...val].filter(x => x !== "All")
            }
        }
    }
};
</script>
