<template>
    <CCol md="12">
        <CCard accent-color="warning">
            <CCardHeader>
                <CRow>
                    <CCol md="10">
                        <span class="font-weight-bold">
                            Báo cáo Khách Hàng, Đơn Hàng -
                        </span>
                        <span class="text-info font-weight-bold">
                            {{ timeBox.label }}
                        </span>
                    </CCol>
                    <CCol md="2">
                        <div class="card-header-actions ">
                            <CLink class="card-header-action btn-minimize ml-3" @click="isCollapsed = !isCollapsed">
                                <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" />
                            </CLink>
                        </div>
                        <div class="card-header-actions">
                            <CDropdown color="transparent p-0" :caret="false" placement="bottom-end" size="sm">
                                <template #toggler-content>
                                    <CIcon name="cil-calendar" custom-classes="c-icon text-dark" />
                                </template>
                                <CDropdownItem v-for="(time, j) in timeList" :key="`time-${j}`" :class="{
                                    active:
                                        timeBox &&
                                        time.fromDateCurrent == timeBox.fromDateCurrent &&
                                        time.toDateCurrent == timeBox.toDateCurrent,
                                }" @click="timeBox = time">
                                    {{ time.label }}
                                </CDropdownItem>
                            </CDropdown>
                        </div>
                    </CCol>
                </CRow>
            </CCardHeader>
            <CCollapse :show="!isCollapsed" :duration="500">
                <CCardBody>
                    <div v-if="loading">
                        <CSpinner color="info" size="sm" class="ml-2" />
                        Đang tải...
                    </div>
                    <div class="d-flex align-items-center justify-content-end mb-3">
                        <v-select style="width: 300px;" v-model="AgencyIds" multiple :options="[
                            { Id: 'All', Name: 'Tất cả' },
                            ...agencies.map((_) => {
                                return {
                                    Id: _.Id.toString(),
                                    Name: _.Name,
                                };
                            }),
                        ]" :reduce="(p) => p.Id" label="Name" />
                    </div>
                    <CRow>
                        <CCol md="4" class="customer-order-card" v-for="customerOrder in customerOrderList">
                            <CWidgetDropdown :color="customerOrder.countCurrent > customerOrder.countPast
                                ? 'success'
                                : customerOrder.countCurrent === customerOrder.countPast
                                    ? 'info'
                                    : 'danger'
                                " :text="`Số lượng : ${customerOrder.countCurrent > 0
        ? customerOrder.countCurrent.toString()
        : '0'}`
        " :header="customerOrder.title">
                                <template #footer>
                                    <div class="m-4 font-italic font-weight-bold">
                                        <CIcon :name="customerOrder.countCurrent > customerOrder.countPast
                                            ? 'cilChevronTop'
                                            : customerOrder.countCurrent ===
                                                customerOrder.countPast
                                                ? 'cilMinus'
                                                : 'cilChevronBottom'
                                            ">
                                        </CIcon>
                                        {{
                                            percentage(
                                                customerOrder.countPast,
                                                customerOrder.countCurrent
                                            ) + " %"
                                        }}
                                        <span v-if="customerOrder.qtyOrderCurrent !== null" class="float-right">SL Đơn hàng : {{
                                            customerOrder.qtyOrderCurrent + 
                                            ` (${percentage(customerOrder.qtyOrderPast, customerOrder.qtyOrderCurrent)} %)` }}</span>
                                        <span v-if="customerOrder.amountAverage !== null" class="float-right">TB đơn ghế :
                                            {{
                                                customerOrder.amountAverage.toLocaleString("en-US") +
                                                " vnd"
                                            }}</span>
                                    </div>
                                </template>
                            </CWidgetDropdown>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCollapse>
        </CCard>
    </CCol>
</template>

<script>
export default {
    name: "test-v2",
    props: ["agencies"],
    data() {
        let timeList = [
            {
                label: `Hôm nay`,
                fromDateCurrent: this.$moment()
                    .startOf("day")
                    .toISOString(),
                toDateCurrent: this.$moment()
                    .endOf("day")
                    .toISOString(),
                fromDatePast: this.$moment()
                    .subtract(1, "days")
                    .startOf("day")
                    .toISOString(),
                toDatePast: this.$moment()
                    .subtract(1, "days")
                    .endOf("day")
                    .toISOString(),
            },
            {
                label: `Hôm qua`,
                fromDateCurrent: this.$moment()
                    .subtract(1, "days")
                    .startOf("day")
                    .toISOString(),
                toDateCurrent: this.$moment()
                    .subtract(1, "days")
                    .endOf("day")
                    .toISOString(),
                fromDatePast: this.$moment()
                    .subtract(2, "days")
                    .startOf("day")
                    .toISOString(),
                toDatePast: this.$moment()
                    .subtract(2, "days")
                    .endOf("day")
                    .toISOString(),
            },
            {
                label: `Tuần này`,
                fromDateCurrent: this.$moment()
                    .startOf("week")
                    .toISOString(),
                toDateCurrent: this.$moment()
                    .endOf("week")
                    .toISOString(),
                fromDatePast: this.$moment()
                    .subtract(1, "weeks")
                    .startOf("week")
                    .toISOString(),
                toDatePast: this.$moment()
                    .subtract(1, "weeks")
                    .endOf("week")
                    .toISOString(),
            },
            {
                label: `Tuần trước`,
                fromDateCurrent: this.$moment()
                    .subtract(1, "weeks")
                    .startOf("week")
                    .toISOString(),
                toDateCurrent: this.$moment()
                    .subtract(1, "weeks")
                    .endOf("week")
                    .toISOString(),
                fromDatePast: this.$moment()
                    .subtract(2, "weeks")
                    .startOf("week")
                    .toISOString(),
                toDatePast: this.$moment()
                    .subtract(2, "weeks")
                    .endOf("week")
                    .toISOString(),
            },
            {
                label: `Tháng này`,
                fromDateCurrent: this.$moment()
                    .startOf("month")
                    .toISOString(),
                toDateCurrent: this.$moment()
                    .endOf("month")
                    .toISOString(),
                fromDatePast: this.$moment()
                    .subtract(1, "months")
                    .startOf("month")
                    .toISOString(),
                toDatePast: this.$moment()
                    .subtract(1, "months")
                    .endOf("month")
                    .toISOString(),
            },
            {
                label: `Tháng trước`,
                fromDateCurrent: this.$moment()
                    .subtract(1, "months")
                    .startOf("month")
                    .toISOString(),
                toDateCurrent: this.$moment()
                    .subtract(1, "months")
                    .endOf("month")
                    .toISOString(),
                fromDatePast: this.$moment()
                    .subtract(2, "months")
                    .startOf("month")
                    .toISOString(),
                toDatePast: this.$moment()
                    .subtract(2, "months")
                    .endOf("month")
                    .toISOString(),
            },
            {
                label: `Năm nay`,
                fromDateCurrent: this.$moment()
                    .startOf("year")
                    .toISOString(),
                toDateCurrent: this.$moment()
                    .endOf("year")
                    .toISOString(),
                fromDatePast: this.$moment()
                    .subtract(1, "years")
                    .startOf("year")
                    .toISOString(),
                toDatePast: this.$moment()
                    .subtract(1, "years")
                    .endOf("year")
                    .toISOString(),
            },
        ];
        return {
            timeList,
            timeBox: timeList[0],
            loading: false,
            AgencyIds: "All",
            customerOrderList: [],
            isCollapsed: true,
        };
    },
    methods: {
        percentage(a, b) {
            return parseFloat(((b - a) / (a > 0 ? a : 1)) * 100).toFixed(2);
        },
        async loadCustomerOrder() {
            var params = {
                fromDateCurrent: this.timeBox.fromDateCurrent,
                toDateCurrent: this.timeBox.toDateCurrent,
                fromDatePast: this.timeBox.fromDatePast,
                toDatePast: this.timeBox.toDatePast,
                AgencyIds:
                    this.AgencyIds === "All"
                        ? "All"
                        : [...this.AgencyIds.map((_) => _.toString())].join(","),
            };
            try {
                this.loading = true;
                let response = await this.$http.get(`Report2/CustomerOrder`, {
                    params: params,
                });
                if (response) {
                    this.customerOrderList = response.data;
                }
                this.loading = false;
            } catch (error) {
                alert(error);
            }
        },
    },
    watch: {
        isCollapsed(val) {
            if (val === false) {
                this.AgencyIds = "All";
                this.loadCustomerOrder();
            } else {
                this.customerOrderList = [];
            }
        },
        timeBox() {
            this.loadCustomerOrder();
        },
        AgencyIds(val) {
            if (
                val.length === 0 ||
                (val.length > 1 && val[val.length - 1] === "All")
            ) {
                this.AgencyIds = "All";
            }

            else if (val.length > 1 && val[0] === "All") {
                this.AgencyIds = [...val].filter((x) => x !== "All");
            }

            else {
                this.loadCustomerOrder();
            }
        },
    },
};
</script>

<style lang="scss">
.customer-order-card {
    &>div>div>div>div {
        &:last-child {
            margin-top: 10px;
            font-weight: bold;
        }
    }
}
</style>
